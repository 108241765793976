const AWARDS = {
  CLUTCH_IMPRESSIT: "https://clutch.co/profile/impressit",
  GOODFIRMS_IMPRESSIT: "https://www.goodfirms.co/company/impressit",
  WADLINE_IMPRESSIT: "https://wadline.com/impressit",
  UPCITY_IMPRESSIT: "https://upcity.com/profiles/impressit",
  THE_MANIFEST_IMPRESSIT:
    "https://themanifest.com/uk/staff-augmentation/services",
  TOP_DEVELOPERS_IMPRESSIT:
    "https://www.topdevelopers.co/press-releases/finest-500-mobile-app-development-companies-of-november-2022",
  AMPLIFYRE_IMPRESSIT: "https://www.amplifyre.com/company/impressit",
}

const MEMBERSHIPS = {
  TECH_UK: "https://www.techuk.org/",
  LVIV_IT_CLUSTER: "https://itcluster.lviv.ua/en/",
  IT_UKRAINE_ASSOCIATION: "https://itukraine.org.ua/en/home/",
}

export const GLOBAL_APPLICATION_EXTERNAL_LINKS = {
  INSTAGRAM: "https://www.instagram.com/impressit_team",
  LINKEDIN: "https://www.linkedin.com/company/impressit",
  YOUTUBE: "https://www.youtube.com/channel/UCjW25p7A1NHBLOzAcxt7Ztg",
  STARTUP_KIT: "https://startup-kit.io",
  ...AWARDS,
  ...MEMBERSHIPS,
}
